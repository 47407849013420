/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
import FormGenerator from '../../../shared/UIKit/FormGenerator';
// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// Form Schema
import schema from '../../../shared/FormSchemas/resetPasswordSchema.json';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Form() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  //  localization
  const { t } = useTranslation();
  const { title, buttonValue, linkValue } = t('form', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="reset-password-form">
      <div className="reset-password-form-container">
        <Container>
          <h1 className="title">{title}</h1>
          <FormGenerator
            schema={schema}
            buttonValue={buttonValue}
            linkValue={linkValue}
          />
        </Container>
      </div>
    </div>
  );
}

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
